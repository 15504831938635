window.onload = init;

function init() {
    var body = document.querySelector('body');
	var navButton = document.querySelector('header button');
	var navItems = document.querySelectorAll('nav a');
	var accordions = document.querySelectorAll('h3.accordion');
	navButton.addEventListener('click', function() {
		if (body.classList.contains('nav-open')) {
			body.classList.remove('nav-open');
		} else {
			body.classList.add('nav-open');
		}
	});

	navItems.forEach(function(item) {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			selectedNavItem = this;
			var href = this.getAttribute('href');
			document.getElementById(href).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
			body.classList.remove('nav-open');
		});
	});

	accordions.forEach(function(accordion) {
		var btn = accordion.querySelector('button');
		var target = accordion.nextElementSibling;

		btn.addEventListener('click', function() {
			var expanded = btn.getAttribute('aria-expanded') === 'true';  
			btn.setAttribute('aria-expanded', !expanded);
			target.hidden = expanded;  
		});
	});
}